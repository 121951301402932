<template>
  <div>
    <section class="main">
      <div class="hero parallax"></div>

      <article>
        <h2>{{ currentRouteName }}</h2>

        <div class="mission-statement">
          &quot;Our primary objective is to serve and protect YOU! Our mission is to ensure a peace of mind that our clients business is running in a safe and secure environment. We do this by delivering highest level of security services and solutions.&quot;
        </div>

        <div class="flex-container">
          <div>
            <h3>Our History</h3>
            <p>Chicagoland-based, GammaTeam Security was established in 2014, Northbrook, Illinois. The most responsive and reliable security company in the industry. At GammaTeam Security we guarantee smooth process of operations, quality, and control of our services by carefully selecting and professionally training our staff on the state level.</p>
          </div>
          <div>
            <h3>Company Culture</h3>
            <p>Our company's culture is based on the achievement of excellence in every aspect of providing security and protection, and exceeding our customers' expectations and needs, with integrity and professionalism. With that in mind, all our Security Officers are certified, insured, bonded and committed to their tasks.</p>
          </div>
          <div>
            <h3>High Standards</h3>
            <p>
              All our employees undergo company's pre-employment screening before being hired: comprehensive criminal background check and fingerprinting; participate in the training to upgrade their knowledge in state laws, patrol techniques and self-defense skills annually.<br />
              <strong>Illinois Certifications:</strong>
              Licensed Private Security Contractor Agency
              License: 122.001324

              Licensed Private Detective Agency
              License: 117.001773<br />
              <strong>Wisconsin Certifications:</strong>
              Private Detective Agency
              License: 17254-62
            </p>
          </div>
          <div>
            <h3>Versatile</h3>
            <p>Our services are designed for small businesses and large corporations with the commitment to highest quality, dedication, and maximum safety. If you feel that your current security program needs improvement, please allow us the opportunity to conduct a complementary security survey for you. The survey will allow us to give you an expert analysis of your property's safety and security program. Furthermore, if you are in need of a new program, we will develop an integrated plan to cover your needs and focus on recommendations that fit within your budget.</p>
          </div>
        </div>

        <call-to-action :link_back=false></call-to-action>

      </article>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data: () => ({
  }),
  methods: {
  }
}
</script>

<style scoped>
section.main div.mission-statement {
  font-style: italic;
  font-size: 1.3em;
  font-weight: 600;
  margin-bottom: 10px;
}
section.main div.flex-container div {
  width: 49%;
  padding: 15px 20px;
  background-color: rgba(0,0,0,.5);
  border-radius: 15px;
  margin: 15px 0;
}
section.main div.flex-container div h3 {
  color: #2a7afa;
  font-size: 1.5em;
}
section.main div.flex-container div p {
  color: white;
  font-size: 1.1em;
  line-height: 1.6em;
  letter-spacing: 0px;
  margin: 10px 0 0 0;
}
section.main div.flex-container div p > strong {
  margin-top: 20px;
  display: block;
}
</style>
